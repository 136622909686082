import React from "react"
import Slider from "react-slick"
import Slide from "react-reveal/Slide"
import { Link } from "gatsby"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
// import HeroSlider, { Slide, SideNav, Nav } from "hero-slider"

import slider1 from "../assets/slider1.jpg"
import slider2 from "../assets/slider2.jpg"
import slider3 from "../assets/slider3.jpg"
import slider4 from "../assets/slider4.jpg"

import heroStyles from "./hero.module.scss"

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
}

const Hero = () => {
  const [index, setIndex] = React.useState(0)
  const length = 4
  const handleNext = () =>
    index === length ? setIndex(0) : setIndex(index + 1)
  const handlePrevious = () =>
    index === 0 ? setIndex(length) : setIndex(index - 1)

  console.log(index)

  return (
    <div className={heroStyles.heroWrapper}>
      <div className={heroStyles.box}>
        <div className={heroStyles.wave} />
        <div className={heroStyles.wave} />
        <div className={heroStyles.wave} />
      </div>

      <div className={heroStyles.slidesContainer}>
        {index === 0 && (
          <>
            <div
              className={heroStyles.slideImage}
              style={{ backgroundImage: `url(${slider1})` }}
            />
            <div className={heroStyles.slideContent}>
              <h1>
                ¡El tiempo no espera <br />a nadie!
              </h1>
              <p>
                ¡Haz crecer tu dinero de una manera fácil y segura! Entre más
                pronto comiences, verás mayores resultados y en menor tiempo.
              </p>
              <Link to="/nosotros">Conoce más</Link>
            </div>
          </>
        )}
        {index === 1 && (
          <>
            <div style={{ backgroundImage: `url(${slider2})` }} />
          </>
        )}
        {index === 2 && (
          <Slide left>
            <div style={{ backgroundImage: `url(${slider3})` }} />
          </Slide>
        )}
        {index === 1 && <div style={{ backgroundImage: `url(${slider2})` }} />}
        {index === 2 && <div style={{ backgroundImage: `url(${slider3})` }} />}
        {index === 3 && <div style={{ backgroundImage: `url(${slider4})` }} />}
      </div>

      <div>
        <button onClick={() => handlePrevious()}>Previous</button>
        <button onClick={() => handleNext()}>Next</button>
      </div>
    </div>
  )
}

// const Hero = () => (
//   <div className={heroStyles.slider}>
//     <Slider {...sliderSettings}>
//       <div>
//         <img src={slider1} />
//       </div>
//       <div>
//         <img src={slider2} />
//       </div>
//       <div>
//         <img src={slider3} />
//       </div>
//       <div>
//         <img src={slider4} />
//       </div>
//     </Slider>
//   </div>
// )
export default Hero
// const Hero = () => (
//   <HeroSlider
//     slidingAnimation="top_to_bottom"
//     orientation="vertical"
//     initialSlide={1}
//     onBeforeChange={(previousSlide, nextSlide) =>
//       console.log("onBeforeChange", previousSlide, nextSlide)
//     }
//     onChange={nextSlide => console.log("onChange", nextSlide)}
//     onAfterChange={nextSlide => console.log("onAfterChange", nextSlide)}
//     style={{
//       backgroundColor: "#000",
//     }}
//     settings={{
//       slidingDuration: 400,
//       slidingDelay: 100,
//       shouldAutoplay: true,
//       shouldDisplayButtons: true,
//       autoplayDuration: 8000,
//       height: "80vh",
//     }}
//   >
//     <Slide
//       shouldRenderMask
//       navDescription="Black Widow"
//       background={{
//         backgroundAttachment: "fixed",
//         backgroundColor: "#6D9B98",
//         backgroundImage: slider1,
//       }}
//     />

//     <Slide
//       shouldRenderMask
//       navDescription="Captain America"
//       background={{
//         backgroundAttachment: "fixed",
//         backgroundColor: "#8A8A8A",
//         backgroundImage: slider2,
//       }}
//     />

//     <Slide
//       shouldRenderMask
//       navDescription="Iron Man"
//       background={{
//         backgroundAttachment: "fixed",
//         backgroundColor: "#EA2329",
//         backgroundImage: slider3,
//       }}
//     />

//     <Slide
//       shouldRenderMask
//       navDescription="Thor"
//       background={{
//         backgroundAttachment: "fixed",
//         backgroundColor: "#2D7791",
//         backgroundImage: slider4,
//       }}
//     />

//     <Nav
//       position={{
//         bottom: "15%",
//         left: "50%",
//         transform: "translateX(-50%)",
//       }}
//     />
//     <SideNav />
//     <SideNav
//       isPositionedRight={false}
//       position={{
//         top: "50%",
//         left: "0",
//         transform: "translateY(-50%)",
//       }}
//     />
//   </HeroSlider>
// )

// export default Hero
