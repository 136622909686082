import React from "react"
import Particles from "react-particles-js"
import Fade from "react-reveal/Fade"
import { Link } from "gatsby"

import { FiUsers, FiAward, FiFile } from "react-icons/fi"

import logo from "../assets/kronos.png"
import serviceBenefits from "../assets/service-benefits.jpg"
import serviceMoney from "../assets/service-money.jpg"
import serviceCollege from "../assets/service-college.jpg"

import allianzLogo from "../assets/allies/allianz.png"
import gnpLogo from "../assets/allies/gnp.png"
import bupaLogo from "../assets/allies/bupa.png"
import segurosMonterreyLogo from "../assets/allies/segurosmonterrey.png"
import qualitasLogo from "../assets/allies/qualitas.png"
import mapfreLogo from "../assets/allies/mapfre.png"

import Hero from "../components/Hero"

import indexStyles from "./index.module.scss"

const IndexPage = () => (
  <div className={indexStyles.container}>
    <Hero />
    <div className={indexStyles.reasonsWrapper}>
      <h2>¿Por qué con nosotros?</h2>

      <div className={indexStyles.flexWrapper}>
        <Fade>
          <div className={indexStyles.textWrapper}>
            <FiFile className={indexStyles.icons} />
            <h4>
              Atención 100% personalizada, asesorías y seguimiento a trámites.{" "}
            </h4>
          </div>
        </Fade>

        <Fade>
          <div className={indexStyles.textWrapper}>
            <FiUsers className={indexStyles.icons} />
            <h4>Equipo especializado en siniestros de cada ramo.</h4>
          </div>
        </Fade>

        <Fade>
          <div className={indexStyles.textWrapper}>
            <FiAward className={indexStyles.icons} />
            <h4>
              Agentes capacitados para brindarte la información más actualizada
            </h4>
          </div>
        </Fade>
      </div>
    </div>

    <h2>Nuestros productos más solicitados</h2>

    <div className={indexStyles.flexWrapper}>
      <Fade>
        <div className={indexStyles.serviceItem}>
          <h3>Plan Personal de Retiro + Beneficios Fiscales</h3>
          <img
            src={serviceBenefits}
            alt="Plan personal de retiro y beneficios fiscales"
          />
          <div className={indexStyles.serviceItemDescription}>
            <p>
              ¿Pagas demasiado de impuestos? Con esta herramienta deduce parte
              de tus ingresos para disminuir tu base gravable, al mismo tiempo
              que ahorras para tu retiro.
            </p>
            <Link to="/servicios/ahorro/plan-personal-de-retiro-beneficios-fiscales">
              Solicita más información
            </Link>
          </div>
        </div>
      </Fade>

      <Fade>
        <div className={indexStyles.serviceItem}>
          <h3>Ahorro Multiproyecto</h3>
          <img src={serviceMoney} alt="ahorro multiproyecto" />
          <div className={indexStyles.serviceItemDescription}>
            <p>
              Proyecto de aportaciones mensuales en donde construiremos un
              hábito sano de ahorro. Tú indicas la cantidad que te sea cómoda.
              Contarás con liquidez de tu dinero, ya sea para algún proyecto en
              puerta o incluso para una emergencia.
            </p>
            <Link to="/servicios/ahorro/ahorro-inversión/">
              Solicita más información
            </Link>
          </div>
        </div>
      </Fade>

      <Fade>
        <div className={indexStyles.serviceItem}>
          <h3>Ahorro Universitario</h3>
          <img src={serviceCollege} alt="ahorro universitario" />
          <div className={indexStyles.serviceItemDescription}>
            <p>
              La mejor herencia que le puedes dejar a tus hijos es una buena
              educación. Garantizas desde el primer día, que pase lo que pase,
              tus hijos contarán con recursos para que puedan continuar sus
              estudios.
            </p>
            <Link to="/servicios/ahorro/ahorro-educativo">
              Solicita más información
            </Link>
          </div>
        </div>
      </Fade>
    </div>

    <Fade bottom>
      <h2>Nuestras alianzas</h2>
      <div className={indexStyles.alliesWrapper}>
        <div>
          <img src={allianzLogo} alt="Allianz Seguros" />
        </div>
        <div>
          <img src={gnpLogo} alt="GNP Seguros" />
        </div>
        <div>
          <img src={segurosMonterreyLogo} alt="Seguros Monterrey" />
        </div>
        <div>
          <img src={mapfreLogo} alt="Mapfre" />
        </div>
        <div>
          <img src={qualitasLogo} alt="Qualitas" />
        </div>
        <div>
          <img src={bupaLogo} alt="BUPA Seguros" />
        </div>
      </div>
    </Fade>
  </div>
)

export default IndexPage
